import React from 'react';
import cn from 'classnames';

import style from './Button.scss';

const Button = ({ text = 'Submit', disabled = false, type, onClick, className }) => {
  return (
    <button
      type={type}
      className={cn(style.btn, className, {
        'btn--disabled': disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{text}</span>
    </button>
  );
};

export default Button;
