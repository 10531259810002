import React from 'react';
import Input from '../Input';
import Button from '../Button';
import FormErrorMessage from '../FormErrorMessage';

const PasswordForgetForm = ({ onSubmit, onChange, isInvalid, error, email, className }) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      <Input
        name="email"
        value={email}
        onChange={onChange}
        labelName="Email Address"
        type="email"
        required
      />

      <Button disabled={isInvalid} type="submit" text="Reset my password" />

      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </form>
  );
};

export default PasswordForgetForm;
