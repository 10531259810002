import React, { Component } from 'react';
import PasswordForget from '../../PasswordForget';
import { Link } from 'gatsby';

import style from './PasswordForget.scss';

class PasswordForgetPage extends Component {
  render() {
    return (
      <div className={`container ${style.container}`}>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-6 ${style.content}`}>
            <PasswordForget />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-6 ${style.content}`}>
            <p>
              <Link to="/">Return home</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordForgetPage;
