import React, { Component } from 'react';

import { withFirebase } from '../../utils/Firebase';
import PasswordForgetForm from './Form';

const INITIAL_STATE = {
  email: '',
  error: null,
  isEmailSent: false
};

class PasswordForget extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, isEmailSent: true });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, isEmailSent } = this.state;
    const { className } = this.props;

    const isInvalid = email === '';

    return isEmailSent ? (
      <>
        <h1>Email sent</h1>
        <p>
          Please check your email to reset password
        </p>
      </>
    ) : (
      <>
        <h1>Forgot your password?</h1>
        <PasswordForgetForm
          className={className}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          email={email}
          error={error}
          isInvalid={isInvalid}
        />
      </>
    );
  }
}

export default withFirebase(PasswordForget);
